<script src="//unpkg.com/v-viewer/dist/v-viewer.js"></script>
<template>
  <div>
    <div v-if="loading">
      <span class="spinner"></span>
    </div>
    <div>
      <b-form @submit.stop.prevent="onSubmit">
        <b-form-group id="group-pincode" label-for="input-pincode">
          <template v-slot:label>
            Pin Code
            <span class="text-danger">*</span>
          </template>

          <b-form-input
            id="input-pincode"
            :state="$v.form.pincode.$dirty ? !$v.form.pincode.$error : null"
            type="text"
            disabled
            v-model="form.pincode"
            placeholder="Enter Pincode"
          ></b-form-input>
          <b-form-invalid-feedback id="input-pincode-invalid">Please enter pincode</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group id="group-store" label-for="input-store">
          <template v-slot:label> Select Store Id <span class="text-danger">*</span> </template>
          <b-form-input
            id="input-store"
            type="text"
            disabled
            v-model="selectedStoreId"
            placeholder="Enter store"
          ></b-form-input>
          <!-- :state="$v.form.pincode.$dirty ? !$v.form.pincode.$error : null" -->
          <!-- <multiselect
            v-model="selectedStoreId"
            ref="multiselect"
            disabled
            placeholder="Select Store Ids"
            :options="userStoreIds"
          >
          </multiselect> -->
        </b-form-group>
        <b-form-group id="group-mobile" label-for="input-mobile">
          <template v-slot:label>
            Mobile Number
            <span class="text-danger">*</span>
          </template>
          <b-input-group>
            <b-form-input
              id="input-mobile"
              v-model="mobile"
              type="number"
              :formatter="limit"
              :disabled="formType !== 'new'"
              placeholder="Enter Mobile Number"
              :state="$v.mobile.$dirty ? !$v.mobile.$error : null"
            ></b-form-input>
            <!-- <b-input-group-append>
              <b-button variant="success" :disabled="mobile.length !== 10 || !mobile" @click="fetchUserInfo"
                >Fetch User Data</b-button
              >
            </b-input-group-append> -->
          </b-input-group>

          <b-form-text id="input-mobile-help">Mobile Number must be a valid 10 digit long number.</b-form-text>

          <b-form-invalid-feedback id="input-mobile-invalid">Please enter valid mobile number.</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group id="group-name" label-for="input-name">
          <template v-slot:label>
            Full Name (First and Last Name)
            <span class="text-danger">*</span>
          </template>

          <b-form-input
            id="input-name"
            type="text"
            v-model="form.name"
            :disabled="formType !== 'new'"
            :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
            placeholder="Enter Name"
          ></b-form-input>

          <b-form-invalid-feedback id="input-name-invalid">Please enter name.</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="group-name" label-for="input-email">
          <template v-slot:label>
            Email
            <span class="text-danger">*</span>
          </template>

          <b-form-input
            id="input-name"
            type="email"
            v-model="form.email"
            :state="$v.form.email.$dirty ? !$v.form.email.$error : null"
            :disabled="formType !== 'new'"
            placeholder="Enter Email or type spencerstest0@gmail.com"
          ></b-form-input>
        </b-form-group>
        <b-form-group id="group-products" label-for="input-products">
          <template v-slot:label> Add Products </template>
          <b-form-row v-for="index in value.length + 1" :key="index - 1">
            <b-col cols="8" @click="selectedMulti(index - 1)">
              <multiselect
                :disabled="index != value.length + 1"
                v-model="value[index - 1]"
                ref="multiselect"
                tag-placeholder="Add this as new product"
                placeholder="Add Products"
                label="productName"
                :custom-label="labelWithPrice"
                track-by="productName"
                :options="productOptions"
                :searchable="true"
                :internal-search="false"
                :clear-on-select="true"
                :close-on-select="true"
                :options-limit="20"
                :hide-selected="true"
                :loading="isLoading"
                :multiple="false"
                @search-change="asyncFind"
                id="productId"
              >
              </multiselect>
            </b-col>
            <b-col cols="1" v-bind:style="{ padding: '8px', textAlign: 'center' }">X</b-col>
            <b-col cols="2">
              <b-form-input
                v-bind:style="{ height: '40px !important' }"
                type="number"
                ref="inputquantity"
                v-model="quantity[index - 1]"
                placeholder="1"
              ></b-form-input>
            </b-col>
            <b-col cols="1"> <b-button @click="deleteProduct(index)" variant="danger">Delete</b-button> </b-col>
          </b-form-row>
        </b-form-group>
        <b-modal ref="houseStreetModal" id="houseStreetModal" size="lg" title="Add Customer Address">
          <b-col> </b-col>
          <b-col>
            <b-form-group id="group-search" label-for="input-search">
              <template v-slot:label>
                Search Locality
                <span class="text-danger">*</span>
              </template>
              <multiselect
                v-model="searchAddress"
                ref="multiselect"
                placeholder="Search Landmark"
                label="text"
                :options="addressSuggestion"
                :searchable="true"
                :internal-search="false"
                :clear-on-select="true"
                :close-on-select="true"
                :options-limit="40"
                :hide-selected="true"
                :loading="isLoading"
                :multiple="false"
                :taggable="true"
                @search-change="asyncFindAddress"
                @tag="addTag"
                id="address"
              >
              </multiselect>
            </b-form-group>
          </b-col>
          <div slot="modal-footer" class="w-100 d-flex justify-content-end">
            <b-button class="ml-2 mb-2" @click="handleOk"> OK</b-button>
            <b-button class="ml-2 mb-2" variant="danger" @click="handleOk"> Cancel</b-button>
          </div>
        </b-modal>

        <b-form-group id="group-name" label-for="input-name">
          <template v-slot:label>
            Address
            <span class="text-danger">*</span>
          </template>

          <b-form-input
            id="input-locality"
            type="text"
            v-model="form.locality"
            :disabled="formType !== 'new'"
            placeholder="Enter Locality"
            :state="$v.form.locality.$dirty ? !$v.form.locality.$error : null"
            @click="openHouseStreetModal"
          ></b-form-input>
          <b-form-invalid-feedback id="input-locality-invalid">Please enter locality.</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="group-name" label-for="input-street">
          <template v-slot:label>
            Flat, House No., Building
            <span class="text-danger">*</span>
          </template>

          <b-form-input
            id="input-name"
            type="text"
            :disabled="formType !== 'new'"
            v-model="form.street"
            :state="$v.form.street.$dirty ? !$v.form.street.$error : null"
            placeholder="Enter Street"
          ></b-form-input>
          <b-form-invalid-feedback id="input-street-invalid">Please enter street name.</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="group-city" label-for="input-city">
          <template v-slot:label>
            Town/City
            <span class="text-danger">*</span>
          </template>

          <b-form-input
            id="input-city"
            type="text"
            v-model="form.city"
            :disabled="formType !== 'new'"
            :state="$v.form.city.$dirty ? !$v.form.city.$error : null"
            placeholder="Enter City"
          ></b-form-input>
          <b-form-invalid-feedback id="input-city-invalid"
            >Please enter city name.</b-form-invalid-feedback
          > </b-form-group
        ><b-form-group id="group-state" label-for="input-state">
          <template v-slot:label>
            State
            <span class="text-danger">*</span>
          </template>

          <multiselect
            v-model="form.state"
            ref="multiselect"
            placeholder="Select State"
            :disabled="formType !== 'new'"
            :options="stateOptions"
            label="text"
          ></multiselect>

          <!-- <b-form-select v-model="form.state" :options="stateOptions"> </b-form-select> -->
          <b-form-invalid-feedback id="input-state-invalid">Please enter state name.</b-form-invalid-feedback>
        </b-form-group>

        <!-- <b-form-group id="group-description" label-for="input-description">
          <template v-slot:label> Order Remarks </template>

          <b-form-textarea
            id="input-description"
            type="text"
            rows="1"
            v-model="form.remarks"
            placeholder="Enter Order Remarks If Any"
          ></b-form-textarea>
        </b-form-group> -->
        <b-form-group id="group-product" label-for="select-product">
          <template v-slot:label>
            <h5 style="margin-top: 5%; margin-bottom: 2%"> Choose a payment method </h5>
          </template>

          <multiselect
            id="selectMethod"
            placeholder="Select Payment Method"
            v-model="form.payment_method"
            :options="options"
            disabled
            @input="toggleSelected(value)"
          ></multiselect>
        </b-form-group>
        <template v-if="productError && !this.value.length">
          <b-row class="mb-2">
            <b-col class="text-danger message-col">{{ productError }}</b-col>
          </b-row>
        </template>
        <template v-if="productIdError">
          <b-row class="mb-2">
            <b-col class="text-danger message-col">{{ productIdError }}</b-col>
          </b-row>
        </template>
        <template v-if="storeError">
          <b-row class="mb-2">
            <b-col class="text-danger message-col">{{ storeError }}</b-col>
          </b-row>
        </template>

        <b-row>
          <b-col>
            <b-button v-if="this.formType === 'new'" type="submit" size="sm" variant="success" :disabled="loading">
              <span class="spinner spinner-white" v-if="loading"></span>
              <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Check Availability
            </b-button>
          </b-col>
          <b-col class="text-right">
            <b-button size="sm" variant="warning" :to="{ path: `${listUrl}` }">
              <font-awesome-icon :icon="['fas', 'long-arrow-alt-left']" class="mr-1" />Back to list
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <link href="//unpkg.com/viewerjs/dist/viewer.css" rel="stylesheet" />
  </div>
</template>

<script>
import { numeric, maxLength, required, minLength } from 'vuelidate/lib/validators';
import Multiselect from 'vue-multiselect';
import { mapGetters, mapState, mapActions } from 'vuex';
import _ from 'lodash';
import Catalogue from '../model/catalogue';
import VueViewer from 'v-viewer';
import Vue from 'vue';
import TableBox from './TableBox.vue';
import router from '@/router';

Vue.use(VueViewer);

export default {
  name: 'OrderFormBox',
  props: {
    listUrl: String,
    userType: String,
    formType: String,
    customerMobile: Number,
    customerName: String,
    customerAddress: String,
    stateOptions: Array,
    userId: {
      type: Number,
      required: false
    }
  },
  components: {
    Multiselect,
    TableBox
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: this.metaDescription
        }
      ]
    };
  },
  data() {
    return {
      title: '',
      formLoaded: false,
      options: ['CASH'],
      searchAddress: '',
      addressSuggestion: [],
      mobile: '',
      form: {
        email: '',
        name: null,
        remarks: '',
        storeid: null,
        locality: '',
        city: '',
        street: '',
        pincode: '',
        state: [],
        state_code: 'IN',
        payment_method: 'CASH'
      },
      productError: null,
      productIdError: null,
      storeError: null,
      value: [],
      quantity: [1],
      selectedMultiBoxIndex: null,
      productOptions: [],
      isLoading: false,
      timeout: null,
      selectedStoreId: ''
    };
  },
  validations() {
    const formValidation = {
      name: {
        required
      },
      email: {
        required
      },
      city: {
        required
      },
      street: {
        required
      },
      locality: {
        required
      },
      state: {
        required
      },
      pincode: {
        required,
        numeric,
        maxLength: maxLength(6),
        minLength: minLength(6)
      }
    };
    const mobile = { required, numeric, maxLength: maxLength(10), minLength: minLength(10) };
    if (this.formType === 'new') {
      formValidation.name.required = required;
      formValidation.email.required = required;
      formValidation.locality.required = required;
      formValidation.city.required = required;
      formValidation.street.required = required;
      formValidation.state.required = required;
      formValidation.pincode.required = required;
      mobile.required = required;
    }
    return { form: formValidation, mobile };
  },
  async mounted() {
    const pincode = this.storeIdPinCode;
    this.form.pincode = this.storeIdPinCode?.pincode;
    this.selectedStoreId = this.storeIdPinCode?.storeid?.toUpperCase();
    const formTypeLocal = await this.formType;

    if (this.tempOrder?.products !== undefined && this.tempOrder?.products?.length > 0) {
      this.mobile = this.tempOrder.customerMobile;
      this.form.email = this.tempOrder.email;
      this.form.name = this.tempOrder.name;
      this.form.remarks = this.tempOrder.remarks;
      this.selectedStoreId = this.tempOrder.storeid;
      this.form.city = this.tempOrder.city;
      this.form.locality = this.tempOrder.locality;
      this.form.street = this.tempOrder.street;
      this.form.pincode = this.tempOrder.pincode;
      this.form.state = { text: this.tempOrder?.state, value: this.tempOrder?.state };
      this.form.state_code = this.tempOrder.stateCode;
      this.form.payment_method = this.tempOrder.payment_method;

      const value = this.tempOrder?.products?.map((product, index) => {
        this.quantity[index] = product?.split(':::').length > 1 ? product.split(':::')[1] : 1;
        return {
          productName: product?.split(':::').length > 1 ? product.split(':::')[0] : product,
          id: product?.substring(0, 2) + Math.floor(Math.random() * 10000000),
          articleCode: product?.split(':::').length > 1 ? product.split(':::')[2] : product
        };
      });
      this.value = value;
    }
    this.$nextTick(async () => {
      // Code that will run only after the entire view has been re-rendered
      if (this.formType === 'new') {
        this.formLoaded = true;
        this.$v.$touch(); // Set initial validation
        this.$v.$reset(); // Reset $dirty
      }
    });
  },
  computed: {
    metaDescription() {
      return this.formType === 'new' ? 'Add new order' : 'Update order';
    },
    userStoreIds() {
      return this.user.storeids;
    },
    ...mapGetters('alert', ['errorMessages']),
    ...mapState('order', ['loading', 'order', 'tempOrder', 'storeIdPinCode', 'userData', 'addressSuggestions']),
    ...mapState('auth', ['user']),
    ...mapState('catalogue', ['productsList']),
    pos() {
      return this.order.posItems.map(i => ({
        ...i
      }));
    }
  },
  methods: {
    limit(e) {
      return e.slice(0, 10);
    },
    openHouseStreetModal() {
      this.$refs['houseStreetModal'].show();
    },
    handleOk() {
      this.$refs['houseStreetModal'].hide();
    },
    toggleSelected(value) {
      this.value = value;
    },
    labelWithPrice(product) {
      if (product.productName !== undefined && product.price !== undefined) {
        return `${product.productName} - ₹ ${product.price}`;
      } else {
        return `${product.productName}`;
      }
    },
    fetchUserInfo() {
      this.$emit('fetch-user', { customerMobile: this.mobile });
    },
    onSubmit() {
      this.productError = null;
      this.productIdError = null;
      this.$v.$touch();
      if (_.isEmpty(this.value)) {
        this.productError = 'Please add at least one product';
        return false;
      }
      this.value.map(item => {
        if (!item.articleCode) {
          this.productIdError = 'Please select listed products';
          return false;
        }
      });
      if (this.selectedStoreId === '') {
        this.storeError = 'Please select a store';
        return false;
      }
      if (this.$v.$invalid) {
        return false;
      }
      const products = this.value.map(
        (product, index) =>
          `${product.productName}:::${this.quantity[index] ? this.quantity[index] : 1}:::${product.articleCode}`
      );
      const order = {
        ...this.form,
        state: this.form.state.value,
        email: this.form.email || 'spencerstest0@gmail.com',
        customerMobile: parseInt(this.mobile, 10),
        storeid: this.selectedStoreId,
        source: 'SINGLE_FORM_ENTRY',
        products
      };
      if (this.formType === 'new') {
        this.$emit('add', { order });
      } else {
        this.$emit('edit', { order });
      }
      return false;
    },
    deleteProduct(index) {
      this.value.splice(index - 1, 1);
    },
    asyncFindAddress(search) {
      clearTimeout(this.timeout);
      if (search?.length > 2) {
        this.timeout = setTimeout(() => {
          const updatedText = `${search}`;
          this.$emit('search-address', { search: updatedText });
          this.isLoading = true;
        }, 2000);
      }
    },
    asyncFind(query) {
      clearTimeout(this.timeout);
      if (query?.length > 2) {
        this.timeout = setTimeout(() => {
          this.$emit('find', { query });
          this.isLoading = true;
        }, 300);
      }
    },
    selectedMulti(index) {
      this.selectedMultiBoxIndex = index;
    },
    addTag(newTag) {
      const tag = new Catalogue({
        productName: newTag,
        id: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000)
      });
      this.productOptions.push(tag);
      this.value.push(tag);
      setTimeout(() => {
        this.$refs.inputquantity[this.value.length - 1].$refs.input.focus();
      }, 300);
    }
  },
  watch: {
    mobile(newValue) {
      if (this.formType === 'new' && newValue.length === 10) {
        this.fetchUserInfo();
      }
    },
    userData(newValue) {
      if (newValue?.address !== undefined) {
        const houseNo = newValue?.address.split('%')[0];
        const locality = newValue?.address.split('%')[1];
        const city = newValue?.address.split('%')[2];
        const stateAddress = newValue?.address.split('%')[3];
        const compareState = this.stateOptions.find(state => state.text === stateAddress);
        this.form.state = compareState;
        this.form.locality = locality;
        this.form.name = newValue?.name;
        this.form.email = newValue?.email;
        this.form.street = houseNo;
        this.form.city = city;
      }
    },
    formType(newValue) {
      if (newValue === 'new') {
        if (this.form.pincode === undefined || this.selectedStoreId === undefined) {
          router.push('/online-order-list');
        }
      }
    },

    tempOrder(newValue) {
      if (newValue?.name !== undefined && newValue?.name !== '') {
        this.mobile = newValue?.customerMobile;
        this.form.email = newValue?.email;
        this.form.name = newValue?.name;
        this.form.remarks = newValue?.remarks;
        this.selectedStoreId = newValue?.storeid;
        this.form.city = newValue?.city;
        this.form.locality = newValue?.locality;
        this.form.street = newValue?.street;
        this.form.pincode = newValue?.pincode;
        this.form.state = [{ text: newValue?.state, value: newValue?.state }];
        this.form.state_code = newValue?.stateCode;
        this.form.payment_method = 'CASH';
        this.value = newValue?.products;
        this.quantity = newValue?.quantity;
      }
    },
    value() {
      if (this.formType === 'new') {
        setTimeout(() => {
          this.$refs.inputquantity[this.value.length - 1].$refs.input.focus();
        }, 300);
      }
    },
    customerName(_newValue) {
      this.form.name = _newValue;
    },
    customerAddress(_newValue) {
      this.form.address = _newValue;
    },
    productsList(_newValue) {
      this.productOptions = _newValue;
      this.isLoading = false;
    },
    addressSuggestions(newValue) {
      this.addressSuggestion = newValue;
      this.isLoading = false;
    },
    searchAddress(newValue) {
      if (newValue !== '') {
        this.form.locality = newValue.text
          .split(',')
          .slice(0, -3)
          .map(part => part.trim())
          .join(', ');
      }
      this.form.city = newValue?.addressObj.city;
      const compareState = this.stateOptions.find(state => state.text === newValue.addressObj.state);
      this.form.state = compareState;
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped></style>
