<template>
  <div class="page-class page-center">
    <div class="page-staff-form">
      <h1 class="page-title">{{ title }}</h1>
      <order-form-box-v2
        list-url="/online-order-list"
        :formType="formType"
        :stateOptions="stateOptions"
        @add="onAdd"
        @find="onFindProducts"
        @fetch-user="onFetchCustomer"
        @search-address="onSearchAddress"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapActions } from 'vuex';
import OrderFormBoxV2 from '@/components/orderFormBoxV2.vue';

import router from '@/router';

export default {
  name: 'OrderFormMagento',
  components: {
    OrderFormBoxV2
  },
  async mounted() {
    this.setErrorMessage('');
    this.getStateAndStateCode(router);
    if (this.$route.name === 'OrderFormMagento-new') {
      this.formType = 'new';
    } else {
      this.formType = 'update';
      this.orderId = this.$route.params.id;
      await this.getOneOrderMagento({
        id: this.orderId,
        router
      });
    }
  },
  data() {
    return {
      formType: '',
      mobile: null,
      orderId: null,
      customerName: null,
      customerMobile: null,
      customerAddress: null,
      stateOptions: []
    };
  },
  computed: {
    title() {
      return this.formType === 'new' ? 'Add new order' : 'Update order';
    },
    ...mapState('order', [
      'loading',
      'baseUrl',
      'customerOrders',
      'order',
      'fetchedCustomerName',
      'fetchedCustomerAddress',
      'storeIdPinCode',
      'stateCodeList'
    ]),
    ...mapState('auth', ['user'])
  },
  watch: {
    order(newOrder) {
      if (newOrder.customerMobile) {
        this.listByCustomer({ customerMobile: newOrder.customerMobile });
      }
    },
    fetchedCustomerName(newValue) {
      this.customerName = newValue;
    },
    fetchedCustomerAddress(newValue) {
      this.customerAddress = newValue;
    },
    stateCodeList(newValue) {
      if (newValue.length > 0) {
        this.stateOptions = newValue;
      }
    }
  },
  methods: {
    ...mapActions('order', [
      'postOne',
      'patchOne',
      'listByCustomer',
      'checkStockAvailability',
      'postOrderMagento',
      'setErrorMessage',
      'getOneOrderMagento',
      'getStateAndStateCode',
      'findAddress',
      'onlineCustomerData'
    ]),
    ...mapActions('catalogue', ['findProducts', 'findProductsOnline']),
    onSearchAddress(search) {
      this.findAddress({ search });
    },
    onFindProducts({ query }) {
      this.findProductsOnline({ query: { text: query, storeid: this.storeIdPinCode?.storeid?.toUpperCase() } });
    },
    onAdd({ order }) {
      const items = order?.products.map(product => {
        return {
          product_id: product.split(':::')[2],
          qty: product.split(':::')[1]
        };
      });

      const params = {
        storecode: order.storeid?.toLowerCase(),
        items
      };
      this.checkStockAvailability({ params, router, order, redirectUrl: '/order' });
    },
    onFetchCustomer({ customerMobile }) {
      this.onlineCustomerData({ customerMobile });
    },

    onEditRow({ row }) {
      // router.push(`/order/${row.orderId}`);
      const { href } = router.resolve({
        path: `/order/${row.orderId}`
      });
      window.open(href, '_blank');
    },
    onDelete({ row }) {
      if (this.user.storeid !== row.storeid) {
        Vue.swal({
          title: 'Sorry, you can not delete an order of other store..',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Okay'
        });
      } else {
        Vue.swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this.",
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',

          preConfirm: () => {
            this.deleteOne({
              id: row.orderId
            });
          }
        });
      }
    }
  }
};
</script>
